<template>
  <div style="width: 100%;height: 100%;">
    <div class="content">
      <div class="contentL">
        <div class="contentLtitle">组织结构</div>
        <el-input class="inputValue" v-model="inputValue" placeholder="请输入关键词搜索" @click="handleSearch"
          suffix-icon="el-icon-search">
        </el-input>
        <div class="tree-container">
          <el-tree ref="tree" :default-expand-all="false" :show-checkbox="false" :data="treeData" :check-on-click-node="false
            " :props="defaultProps" node-key="id" @node-click="handleCheck"></el-tree>
        </div>

      </div>
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">碳排分析</div>
        </div>

        <div class="searchContent">
          <div class="titleE">
            <img src="../../assets/images/titleT.png" alt="">
          <div class="promptText">碳排分析情况统计</div>
          </div>
          <div class="titleEcharts">{{ checkedNodes.label }}场站名称</div>
          <div class="search">
            <div class="newView">
              <!-- <el-button type="primary" @click="openViewDialog" icon="el-icon-plus" class="custom-button">新建公告</el-button> -->

              <div class="day" v-for="(item, index) in dayList" :key="index" :class="day == index ? 'day1' : ''"
                @click="dayFn(index)">{{ item }}</div>
            </div>
            <div class="formInline">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="日期：">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input"
                v-model="formInline.updateTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            </el-form>

            </div>
          </div>
        </div>
      
        
        <div class="searchContent">
          <div id="myEcharts" class="mycharts">
          </div>
        </div>
        <div class="table">
        <div class="bottomChart">
          <div class="chartL">
            <div class="chartTitile">
              <img src="../../assets/images/titleT.png" alt="">
              <div class="">年度节能减碳目标</div>
            </div>
            <div class="chartCont">
              <div class="chartimg">
                <div class="imgTitle">
                  年度目标
                </div>
                <div class="imgnumber">
                  1528.00
                </div>
                <div class="imgbottom">
                  减少CO₂排放量(吨)
                </div>
              </div>
            </div>


          </div>
          <div class="chartR">
            <div class="chartTitile">
              <img src="../../assets/images/titleT.png" alt="">
              <div class="">年度目标完成情况</div>

            </div>
            <div id="chartEcharts"></div>
          </div>
        </div>
        </div>

      </div>
    </div>



  </div>
</template>

<script>
import { boardList } from '../../api/dailyWork.js'

import * as echarts from "echarts";
export default {
  name: 'Administration',
  data() {
    return {
      formInline: {
        title: '',//标题
        content: '',//内容
        createBy: '',//创建人
        updateTime: '',//创建时间
      },
      


      textarea: '',

      treeData: [
        {
          id: 1,
          label: '分中心名称1',
          children: [
            {
              id: 11,
              label: '场站名称1',
              children: [
                { id: 111, label: '配电室' },
                { id: 112, label: '箱变' },
                { id: 113, label: '柱变' },
                {
                  id: 114, label: '强电间',
                  children: [
                    { id: 1141, label: '高压侧' },
                    { id: 1142, label: '低压侧' },
                    { id: 1143, label: '变压器' },
                    { id: 1144, label: '直流屏' },
                  ]
                },
              ],
            },
            {
              id: 12,
              label: '场站名称2',
              children: [
                { id: 121, label: '配电室' },
                { id: 122, label: '箱变' },
                { id: 123, label: '柱变' },
                { id: 124, label: '强电间', }

              ]

            },
          ],
        },
        {
          id: 2,
          label: '分中心名称2',
          children: [
            {
              id: 21,
              label: '场站名称1',
              children: [
                { id: 211, label: '配电室' },
                { id: 212, label: '箱变' },
                { id: 213, label: '柱变' },
                { id: 214, label: '强电间', }

              ]
            },
            {
              id: 22,
              label: '场站名称2',
              children: [
                { id: 221, label: '配电室' },
                { id: 222, label: '箱变' },
                { id: 223, label: '柱变' },
                { id: 224, label: '强电间', }

              ]
            },
          ],
        },
      ],
      checkedNodes: [],
      selectAll: false,
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      defaultProps: {
        children: 'children',
        id: 'id',
        label: 'label'
      },
      dayList: ['按日', '按周','按月','按季', '按年'],
      day: 0,

    };
  },
  // components:{demo},

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    // this.getList()
    this.drawEnergy()
    this.drawCarbon()

  },
  computed: {

  },


  methods: {
    dayFn(el) { this.day = el },
    // tree搜索
    handleSearch() {

    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      // this.updateSelectAllState();
      console.log(this.checkedNodes);
    },
    //列表查询
    getList() {
      let obj = {
        ...this.formInline,
        page: this.currentPage,
        pageSize: this.pageSize
      }
      boardList(obj).then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data
        }
      })
    },
    //查看
    replyDialog(el) {
      console.log(el.data);
      this.centerDialogVisible = true
    },
    //查询列表
    onSubmit() {
      console.log('submit!');
    },

    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      console.log(item, content);
      this.treeText = item.label

      this.selectContent = content.label
    },
    drawEnergy() {
      var myEcharts = echarts.init(document.getElementById("myEcharts"));
      var option = {
        grid: {
          left: '1%',
          right: '1%',
          containLabel: true
        },
        tooltip: {},
        legend: {
          data: ["碳排分析"],
          right: '31px',
          top: '16px',
          textStyle: {
            color: "#AED6FF"
          }
        },
        backgroundColor: '#071B42',
        xAxis: {
          data: [
            "00:01",
            "00:02",
            "00:03",
            "00:04",
            "00:05",
            "00:06",
            "00:07",
            "00:08",
            "00:09",
            "00:10",
            "00:11",
            "00:12",
            "00:13",
            "00:14",
            "00:15",
            "00:16",
            "00:17",
            "00:18",
            "00:19",
            "00:20",
            "00:21",
            "00:22",
            "00:23",
            "00:24",
          ],
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: '#aed6ff' // 设置 x 轴标签字体颜色
            }
          }

        },
        yAxis: {
          name: "单位：",
          nameTextStyle: {
            color: '#aed6ff' 
        },
          splitLine: {
            lineStyle: {
              type: 'dashed',//
              color: "#0b2159"
            },
          },
          axisLabel: {
            textStyle: {
                color: '#aed6ff' // 
            }
        }
        },
        series: [
          {
            name: "碳排分析",
            type: "bar",

            data: [
              5, 20, 36, 10, 10, 20, 5, 20, 36, 10, 10, 20, 5, 20, 36, 10, 10,
              20, 5, 20, 36, 10, 10, 20,
            ],
            barWidth: '30%', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#176AD2'
            }
          },
        ],
      };

      myEcharts.setOption(option);
    },
    drawCarbon() {
      let myChart = echarts.init(document.getElementById("chartEcharts"))

      // 绘制图表
      var option = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'right',
    top:'40%',
    textStyle: {
      fontSize:16,
            color: '#aed6ff' // 设置图例文字颜色为蓝色
        },
    
  },
  color: ['#2FC9CB', '#1769CF', ],
  series: [
    {

      type: 'pie',
      radius: ['60%', '80%'],
      avoidLabelOverlap: false,
     
     label: {
            show: true,
            fontSize: 16 ,// 设置字体大小为12px
            formatter: '{c}' +'%',
            color: function(params) { // 设置回调函数
                return params.color; // 返回当前数据项的颜色
            }

        },
      emphasis: {
        label: {
          show: true,
          fontSize: 16,
          fontWeight: 'bold',
         
        }
      },
      labelLine: {
        show: true,

      },
      data: [
        { value: 63, name: '已完成' },
        { value: 37, name: '未完成' },
       
      ]
    }
  ]
};
   
      myChart.setOption(option);

    }


  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/routerImg.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  padding: 10px 25px 10px 26px;
  background-color: #071B42;
  margin: 0 20px;
}

.search {
  display: flex;
  /* padding: 0 10px; */
  justify-content: space-between;
  align-items: center;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183F !important;
  color: #FFC74A;
}

::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.newView {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.day {
  background-color: #041B44;
  /* background-color: #002C6C; */
  cursor: pointer;
  
  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {
  background-color: #002C6C;
  color: #ffc74a;
}

.day1 {
  background-color: #002C6C;
  color: #ffc74a;

}

.mycharts {
  width: 100%;
  height: 500px;
  margin-bottom: 15px;
}

.titleEcharts {
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
}
.formInline{
  padding-top: 15px;
}
.titleE{
  display: flex;
  align-items: center;
  padding: 10px 0 15px 0;
}
.bottomChart{
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chartL,.chartR{
  height: 100%;
  width: 49%;
  text-align: center;
  background: #071A40;
}
.chartTitile{

  display: flex;
  align-items: center;
  margin: 26px 0 0 16px;
}
.chartCont{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.chartimg{
  width: 190px;
  height: 190px;
  background: url(../../assets/images/circularBlue.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
}
.chartimg{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.imgTitle{
  color: #e8f4ff;
  height: 26px;
  line-height: 26px;

}
.imgnumber{
  color: #2fc9cb;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
  padding: 8px 0 4px 0;
}
.imgbottom{
  color: #e8f4ff;
  height: 26px;
  line-height: 26px;
font-size: 14px;
}
#chartEcharts{
  margin-top: 30px;
  width: 600px;
  height: 250px;
}
::v-deep.el-tree{
  width: 300px;
} 

</style>